import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getDataWithAuth } from "../api/api";
import { useNavigate } from "react-router-dom";

const LoggedinProfile = () => {

    const [profile, setProfile] =useState()
    const navigate = useNavigate();
    const fetchuserProfile = async () => {
        try {
            const response = await getDataWithAuth('api/user/details');
            if (response) {
                console.log(response, "response")
                setProfile(response.data);
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    const logout =()=>{
        localStorage.removeItem("token");
        navigate('/');
    }


    useEffect(() =>{
        fetchuserProfile()
    }, [])
    return (
        <div className="profile">
            <div className="profileinfo">
                <img className="adminImg" src={profile && profile.imgUrl} />
                <label>{profile && profile.name}
                    <span>Admin</span>
                </label>
            </div>
            <Dropdown className="customDropdown">
                <Dropdown.Toggle id="dropdown-basic">
                    <img className="filterIcon" src={process.env.PUBLIC_URL + 'images/pages/arrow_icon.svg'} alt="arrow icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={logout} >Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default LoggedinProfile 