import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/customStyle.css';
import './css/login.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './pages/Login';
import CreateSewa from './pages/CreateSewa';
import Dashboard from './pages/Dashboard';
import UpcomingEvents from './pages/UpcomingEvents';
import EventList from './pages/EventList';
import UserProfiles from './pages/UsersProfiles';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageTeam from './pages/ManageTeam/ManageTeam';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/createSewa" element={<CreateSewa />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/upcomingEvents" element={<UpcomingEvents />} />
        <Route path='/eventList' element={<EventList />} />
        <Route path='/userProfiles' element={<UserProfiles />} />
        <Route path='/manageTeam' element={<ManageTeam />} />
      </Routes>
      <ToastContainer />
    </Router>
    
  );
}

export default App;
