import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getDataWithAuth } from '../../api/api';
import { API_URL } from '../../config/config';
import { toast } from 'react-toastify'; 
import DeleteProfile from '../modal/DeleteProfile';

const Role = () => {
    const token = localStorage.getItem('token');
    const [roles, setRoles] = useState([]);
    const [editingRole, setEditingRole] = useState(null);
    const [newRoleLabel, setNewRoleLabel] = useState('');
    const [addingRole, setAddingRole] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        fetchRoles(); 
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await getDataWithAuth('admin/api/organization/role/list');
            if (response) {
                const roles = response.data.map(role => ({ value: role.id, label: role.name }));
                setRoles(roles);
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const startEditing = (role) => {
        setEditingRole({ ...role });
        setNewRoleLabel(role.label);
    };

    const saveEdit = async () => {
        if (!editingRole) return;

        try {
            await axios.put(`${API_URL}admin/api/organization/role/update/${editingRole.value}`, {
                name: newRoleLabel
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchRoles();
            setEditingRole(null);
        } catch (error) {
            console.error('Error saving data', error);
        }
    };

    const cancelEdit = () => {
        setEditingRole(null);
        setNewRoleLabel('');
    };

    const openDeleteModal = (role) => {
        setItemToDelete(role);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);
    };

    const deleteItem = async () => {
        if (!itemToDelete) return;

        const { value } = itemToDelete;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            const url = `${API_URL}admin/api/organization/role/delete/${value}`;
            const deleteResponse = await axios.delete(url, { headers });
            setRoles((prevRoles) => prevRoles.filter((r) => r.value !== value));
            toast.success(deleteResponse.data.message);
            closeDeleteModal();
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'Failed to delete role';
            toast.error(errorMessage);
            console.error(`Failed to delete role:`, error);
        }
    };

    const startAddingRole = () => {
        setAddingRole(true);
        setNewRoleLabel('');
    };

    const saveNewRole = async () => {
        if (!newRoleLabel) {
            toast.error("Role name cannot be empty.");
            return;
        }

        const body = { name: newRoleLabel };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            const url = `${API_URL}admin/api/organization/role/create`;
            const response = await axios.post(url, body, { headers });
            const newRole = response.data;
            const newItem = { value: newRole.data.id, label: newRole.data.name };
            setRoles([...roles, newItem]);
            toast.success(newRole.message);
            setAddingRole(false);
            setNewRoleLabel('');
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
            console.error('Failed to create role:', error);
        }
    };

    const cancelAddRole = () => {
        setAddingRole(false);
        setNewRoleLabel('');
    };

    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Role</th>
                        <th className='text-end'>
                            <button type="button" className="createBtn btn btn-primary addMoreBtn"
                                onClick={startAddingRole}
                                aria-label="Add New Role">
                                <img src="images/sidebar/plus_icon.svg" alt="Add Role" /> Add New Role
                            </button>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addingRole && (
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={newRoleLabel}
                                    onChange={(e) => setNewRoleLabel(e.target.value)}
                                    className="form-control d-inline-block me-2"
                                    aria-label="Role Label"
                                />
                            </td>
                            <td className='text-end'>
                                <button className="btn btn-primary me-2 saveBtn" onClick={saveNewRole}>
                                    Save Role
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={cancelAddRole}
                                    aria-label="Cancel Adding Role"
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    )}
                    {roles.map((role) => (
                        <tr key={role.value}>
                            <td>
                                {editingRole?.value === role.value ?
                                    <input
                                        type="text"
                                        value={newRoleLabel}
                                        onChange={(e) => setNewRoleLabel(e.target.value)}
                                        className="form-control d-inline-block me-2"
                                        aria-label="Role Label"
                                    /> :
                                    <>{role.label}</>
                                }
                            </td>
                            <td className='text-end'>
                                {editingRole?.value === role.value ? (
                                    <div>
                                        <button className="btn btn-primary me-2 saveBtn" onClick={saveEdit}>
                                            Save
                                        </button>
                                        <button className="btn btn-secondary" onClick={cancelEdit}>
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            className="btn btn-primary editBtn"
                                            onClick={() => startEditing(role)}
                                            aria-label={`Edit ${role.label}`}
                                        >
                                            <img className="userImg" src="images/sidebar/edit_icon.svg" alt="Edit" />
                                            Edit
                                        </button>
                                        <button
                                            className="btn deleteBtn"
                                            onClick={() => openDeleteModal(role)}
                                            aria-label={`Delete ${role.label}`}
                                        >
                                            <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="Delete" /> Delete
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Delete Modal */}
            {showDeleteModal && (
                <DeleteProfile
                    show={showDeleteModal}
                    hide={closeDeleteModal}
                    onConfirm={deleteItem}
                    item={itemToDelete}
                />
            )}
        </>
    );
};

export default Role;
