// import React, { useState } from 'react';
// import { Row, Col, Form, Button, } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
// import Select from 'react-select';

// function CreateSewaModal({ show, handleClose }) {

//   const [selectedInvitees, setSelectedInvitees] = useState([]);
//   const [tags, setTags] = useState([]);

//   const handleInviteesChange = (selectedOptions) => {
//     setSelectedInvitees(selectedOptions);
//   };

//   const handleTagInputChange = (newValue, actionMeta) => {
//     if (actionMeta.action === 'create-option') {
//       setTags([...tags, { value: newValue, label: newValue }]);
//     }
//   };

//   const handleTagsChange = (selectedOptions) => {
//     setTags(selectedOptions);
//   };

//   const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' }
//   ];

//   return (
//     <>
//       <Modal size="lg" show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Create New Sewa</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Row>
//             <Col>
//               <Form.Group className="mb-3">
//                 <Form.Label>Sewa Title</Form.Label>
//                 <Form.Control type="text" placeholder="Sewa Title" name="sewaTitle" />
//               </Form.Group>
//             </Col>
//             <Col>
//               <Form.Label>Sewa deline</Form.Label>
//               <Form.Control placeholder="sewa deline" type="date" name="sewa deline" />
//             </Col>
//           </Row>
//           <Col>
//             <Form.Group className="mb-3">
//               <Form.Label>Sewa Description</Form.Label>
//               <Form.Control as="textarea" rows={2} name="sewaDescription" />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group className="mb-3">
//               <Form.Label>Tags</Form.Label>
//               <Select
//                 closeMenuOnSelect={false}
//                 isMulti
//                 options={options}
//                 placeholder="Enter tags"
//                 onChange={handleTagsChange}
//                 onCreateOption={handleTagInputChange}
//                 value={tags}
//               />
//             </Form.Group>
//           </Col>
//           <div className="invitees">
//             <h4>Add Invitees</h4>
//             <Row className="innerInvitees bg-success bg-opacity-10">
//               <Col lg={12}>
//                 <Row>
//                   <Col>
//                     <Select
//                       closeMenuOnSelect={false}
//                       options={options}
//                       placeholder="Zone"
//                       isMulti
//                     />
//                   </Col>
//                   <Col>
//                     <Select
//                       closeMenuOnSelect={false}
//                       options={options}
//                       placeholder="Branch"
//                       isMulti
//                     />
//                   </Col>
//                   <Col>
//                     <Select
//                       closeMenuOnSelect={false}
//                       options={options}
//                       placeholder="Area"
//                       isMulti
//                     />
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col className="mt-2">
//                     <Select
//                       closeMenuOnSelect={false}
//                       options={options}
//                       isMulti
//                       placeholder="Search Invitees"
//                       onChange={handleInviteesChange}
//                       value={selectedInvitees} />
//                   </Col>
//                 </Row>
//               </Col>

//             </Row>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary">
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default CreateSewaModal;


import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
// import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import Select from 'react-select';
function CreateSewaModal({ show, handleClose }) {
  const [selectedInvitees, setSelectedInvitees] = useState([]);
  const [tag, setTag] = useState(null);

  const loadOptions = (inputValue, callback) => {
    // Simulate fetching options from an API
    axios.get(`https://api.example.com/tags?search=${inputValue}`)
      .then(response => {
        const options = response.data.map(tag => ({
          value: tag.id,
          label: tag.name
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error fetching options:', error);
        callback([]);
      });
  };

  const handleInviteesChange = (selectedOptions) => {
    setSelectedInvitees(selectedOptions);
  };

  const handleTagChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      setTag(newValue);
    } else {
      setTag(newValue);
    }
  };


  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  return (
    <>
      <Modal className='customModal' size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Invitees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="invitees">
            <Row className="innerInvitees">
              <Col lg={12} className='p-0'>
                <Row>
                  <Col>
                    <Select
                      closeMenuOnSelect={false}
                      options={options}
                      placeholder="Zone"
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      closeMenuOnSelect={false}
                      options={options}
                      placeholder="Branch"
                      isMulti
                    />
                  </Col>
                  <Col>
                    <Select
                      closeMenuOnSelect={false}
                      options={options}
                      placeholder="Area"
                      isMulti
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2">
                    <Select
                      closeMenuOnSelect={false}
                      options={options}
                      isMulti
                      placeholder="Search Invitees"
                      onChange={handleInviteesChange}
                      value={selectedInvitees} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className='inviteesList'>
              <Button className='btn btn-link'>Select All</Button>
              <div className='invitee'>
                <div className='user'>
                  <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                  <div className='userName'>
                    <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                    <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                  </div>
                </div>
                <input className='checkBox' type="checkbox" />
              </div>
              <div className='invitee'>
                <div className='user'>
                  <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                  <div className='userName'>
                    <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                    <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                  </div>
                </div>
                <input className='checkBox' type="checkbox" />
              </div>
              <div className='invitee'>
                <div className='user'>
                  <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                  <div className='userName'>
                    <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                    <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                  </div>
                </div>
                <input className='checkBox' type="checkbox" />
              </div>
              <div className='invitee'>
                <div className='user'>
                  <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                  <div className='userName'>
                    <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                    <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                  </div>
                </div>
                <input className='checkBox' type="checkbox" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateSewaModal;
