import React from "react"
import { Link } from "react-router-dom"
import NavBar from "../components/NavBar";
const Dashboard = () => {
    return (
        <>
            <div className="dashboard">
                <section class="py-0 py-xl-5">
                    <div class="container">
                        <div class="row algin-items-center justify-content-center">
                            <div className="col-8">
                                <div class="row g-5 algin-items-center">
                                    <div class="col-sm-6 col-xl-6">
                                        <Link to="/dashboard" class="d-flex flex-column justify-content-center align-items-center p-4 dashBordbox rounded-3">
                                            <span class="display-6 lh-1 m-4 mb-0"> <img className="dashBordIcon" src={process.env.PUBLIC_URL + 'images/dashbord/sadhna_icon.svg'} alt="Sewas" /></span>
                                            <div class="m-4 h6 fw-normal mb-0">
                                                <div class="d-flex">
                                                    <h5 class="purecounter mb-0 fw-bold" ><p class="mb-0">Sadhna</p></h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-sm-6 col-xl-6">
                                        <Link to="/createSewa" class="d-flex flex-column justify-content-center align-items-center p-4 dashBordbox rounded-3">
                                            <span class="display-6 lh-1 m-4 mb-0"> <img className="dashBordIcon" src={process.env.PUBLIC_URL + 'images/dashbord/sewa_icon.svg'} alt="Sewas" /></span>
                                            <div class="m-4 h6 fw-normal mb-0">
                                                <div class="d-flex">
                                                    <h5 class="purecounter mb-0 fw-bold" ><p class="mb-0">Sewa</p></h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-sm-6 col-xl-6">
                                        <Link to="/userProfiles" class="d-flex flex-column justify-content-center align-items-center p-4 dashBordbox rounded-3">
                                            <span class="display-6 lh-1 m-4 mb-0"> <img className="dashBordIcon" src={process.env.PUBLIC_URL + 'images/dashbord/sewa_DarList_icon.svg'} alt="Sewas" /></span>
                                            <div class="m-4 h6 fw-normal mb-0 ">
                                                <div class="d-flex">
                                                    <h5 class="purecounter mb-0 fw-bold" ><p class="mb-0">Users</p></h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div class="col-sm-6 col-xl-6">
                                        <Link to="/eventList" class="d-flex flex-column justify-content-center align-items-center p-4 dashBordbox rounded-3">
                                            <span class="display-6 lh-1 m-4 mb-0"> <img className="dashBordIcon" src={process.env.PUBLIC_URL + 'images/dashbord/upcoming_Events_icon.svg'} alt="Sewas" /></span>
                                            <div class="m-4 h6 fw-normal mb-0">
                                                <div class="d-flex">
                                                    <h5 class="purecounter mb-0 fw-bold" ><p class="mb-0">Events</p></h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Dashboard