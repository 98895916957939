import React from "react"
import { Button, Modal } from "react-bootstrap"

const AssigneeList = ({assigneeShow, assigneeHide}) => {
    return (
        <>

            <Modal className='customModal' size="md" show={assigneeShow} onHide={assigneeHide}>
                <Modal.Header closeButton>
                    <Modal.Title>List of users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="invitees pe-3" id="scrollRight" style={{maxHeight:"450px",}}>
                        <div className='inviteesList'>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus inprogress">In Progress</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus notStarted">Not Started</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus notStarted">Not Started</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                            <div className='invitee'>
                                <div className='user'>
                                    <img className='userImg' src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" />
                                    <div className='userName'>
                                        <label>Jane Cooper <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" /> 25</span></label>
                                        <span className='location'>CHD-16<span className='uid'>UID-1117</span></span>
                                    </div>
                                </div>
                                <span className="sewaStatus completed">Completed</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={assigneeHide}>
                        Close
                    </Button>
                    <Button variant="primary">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AssigneeList