
import React from "react"
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const NavBar = () => {
    return (
        <>
           
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand><Link class="nav-link active" to="/dashboard"><img width="50" class="logo-default" src={process.env.PUBLIC_URL + "images/logo.jpeg"} alt="DJJS" /></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className="navInner">
                            <Nav>
                                <Link class="nav-link active" to="/dashboard">Home</Link>
                                <Link class="nav-link" to="/createSewa">Sewa</Link>
                                <Link class="nav-link" to="/dashboard">Shadhna</Link>
                                <Link class="nav-link" to="/upcomingEvents"> Upcoming Events</Link>
                                <Link class="nav-link" to="/sewaDarList"> Sewadar List</Link>
                            </Nav>
                            <div class="nav-item">
                                <button class="dropdown-item" href="#">Logout</button>
                            </div>
                        </div>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar