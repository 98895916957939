// // src/api/api.js
// // Replace with your API base URL

// import { API_URL } from "../config/config";

// const request = async (url, options) => {
//   try {
//     const token = localStorage.getItem('token');
//     if (token && options.method !== 'POST') {
//       options.headers = {
//         ...options.headers,
//         'Authorization': `Bearer ${token}`,
//       };
//     }

//     const response = await fetch(url, options);
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('Fetch error:', error);
//     throw error;
//   }
// };

// export const getData = async (endpoint) => {
//   const url = `${API_URL}${endpoint}`;
//   return request(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// // Function to handle POST requests
// export const postData = async (endpoint, data) => {
//   const url = `${API_URL}${endpoint}`;
//   return request(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   });
// };

// // Function to handle PUT requests
// export const putData = async (endpoint, data) => {
//   const url = `${API_URL}${endpoint}`;
//   return request(url, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   });
// };

// // Function to handle DELETE requests
// export const deleteData = async (endpoint) => {
//   const url = `${API_URL}${endpoint}`;
//   return request(url, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };


import { API_URL } from "../config/config";

const request = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

// Functions that include Authorization header
const requestWithAuth = async (url, options) => {
  const token = localStorage.getItem('token');
  if (token) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };
  }
  return request(url, options);
};

// Functions that do not include Authorization header
const requestWithoutAuth = async (url, options) => {
  return request(url, options);
};

// With Authorization
export const getDataWithAuth = async (endpoint) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithAuth(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postDataWithAuth = async (endpoint, data) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithAuth(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const putDataWithAuth = async (endpoint, data) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithAuth(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const deleteDataWithAuth = async (endpoint) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithAuth(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// Without Authorization
export const getDataWithoutAuth = async (endpoint) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithoutAuth(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postDataWithoutAuth = async (endpoint, data) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithoutAuth(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const putDataWithoutAuth = async (endpoint, data) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithoutAuth(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const deleteDataWithoutAuth = async (endpoint) => {
  const url = `${API_URL}${endpoint}`;
  return requestWithoutAuth(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
