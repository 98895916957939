import React, { useEffect, useState, useRef, useCallback, } from "react";
import { Button, Card, Container, Dropdown, Form, Row, Col, InputGroup, Pagination, Spinner } from "react-bootstrap";
import DeleteProfile from "./modal/DeleteProfile";
import { Link, useNavigate } from "react-router-dom";
import UserDetail from "../components/UserDetail";
import CreateProfileModal from "./modal/CreateProfileModal";
import { getDataWithAuth, getDataWithoutAuth, postDataWithAuth } from "../api/api";
import LoggedinProfile from "../components/LoggedinProfile";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import ManageTeam from "./ManageTeam/ManageTeam";

const searchOptions = [
    { value: 'name', label: 'Name' },
    { value: 'uid', label: 'UID' },
    { value: 'phone', label: 'Phone' },
    { value: 'areaCode', label: 'Area Code' },
    { value: 'branch', label: 'Branch' },
    { value: 'zone', label: 'Zone' },
];

const UserProfiles = () => {
    const navigate = useNavigate();
    // const profileImg = JSON.parse(localStorage.getItem('profileImg'));
    const [showDeleteProfile, setShowDeleteProfile] = useState(false);
    const [createProfileModal, setCreateProfileModal] = useState(false);
    const [createAllModal, setCreateAllModal] = useState(false);
    const [profilesList, setProfilesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProfiles, setTotalProfiles] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showLeftSidebar, setShowLeftSidebar] = useState(true);
    const [showRightSidebar, setShowRightSidebar] = useState(false);
    const [search, setSearch] = useState();
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teams, setTeams] = useState([]);
    const [orgRole, setOrgRole] = useState(null); // State for orgRole filter
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchType, setSearchType] = useState(null)
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [selectedItem, setSelectedItem] = useState();
    const closeDeleteProfile = () => setShowDeleteProfile(false);
    const closeCreateProfile = () => setCreateProfileModal(false);
    const closeCreateAll = () => setCreateAllModal(false);
    const [manageTeam, setManageTeam] = useState(false)
    const timeoutRef = useRef(null);
    const handleToggleChange = async (userId, isChecked) => {
        const originalProfilesList = [...profilesList];
        const updatedProfilesList = profilesList.map(profile =>
            profile.id === userId ? { ...profile, userRole: isChecked ? "ADMIN" : "VOLUNTEER" } : profile
        );
        setProfilesList(updatedProfilesList);

        let param = {
            uid: userId,
            userRole: isChecked ? "ADMIN" : "VOLUNTEER",
        };
        console.log(param, "param handleToggleChange");

        try {
            const response = await postDataWithAuth(`admin/api/user/changeRole`, param);
            if (response) {
                toast.success(response.message);
            }
            if (!response) {
                throw new Error('Failed to update role');
            }
        } catch (error) {
            console.error('Error updating role', error);
            // Revert the change if the API call fails
            setProfilesList(originalProfilesList);
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // img error function
    const fallbackImageUrl = process.env.PUBLIC_URL + '/images/invite.png';
    const ImageWithFallback = ({ src, fallbackSrc, alt, className }) => {
        const [imgSrc, setImgSrc] = useState(src);
        const handleError = () => {
            setImgSrc(fallbackSrc);
        };
        return (
            <img className={className} src={imgSrc} alt={alt} onError={handleError} />
        );
    };

    // Toggle function for sidebar visibility
    const toggleSidebar = () => {
        setShowLeftSidebar(!showLeftSidebar);
        setShowRightSidebar(!showRightSidebar);
    };

    const backListHandle = () => {
        toggleSidebar()
    }


    const fetchTeams = async () => {
        try {
            const response = await getDataWithAuth('admin/api/organization/team/list');
            const options = response.data.map(team => ({ value: team.id, label: team.name }))
            setTeams(options)
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchTeams()
    }, [])





    const handleSearchOptions = (option) => {
        setSearch('');
        setSelectedOption(option);

    };

    const searchHandleBtn = (keyValue) => {
        setOrgRole(keyValue)
        setSelectedTeam(null);
        setSearch('');
        setProfilesList([]);
        setCurrentPage(1);
    }


    const handleTeamChange = (selectedOption) => {
        setSearch('');
        setSelectedTeam(selectedOption);
        setOrgRole(null);
        setProfilesList([]);
        setCurrentPage(1);
    };



    // const searchHandle = (e) => {
    //     setProfilesList([]);
    //     setSearch(e.target.value);
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //     }
    //     timeoutRef.current = setTimeout(() => {
    //         fetchUserProfile(e.target.value, 1);
    //         setCurrentPage(1);
    //     }, 700);

    //     setSelectedTeam(null);
    //     setOrgRole(null);
        
    //     setCurrentPage(1);

    // }

    const searchHandle = (e) => {
        setSearch(e.target.value); 
        setSelectedTeam(null);
        setOrgRole(null);
        setCurrentPage(1);
    };

    const handleSearchClick = () => {
        setProfilesList([]); 
        fetchUserProfile(search, 1); 
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchUserProfile(search, currentPage);
    }, [currentPage, orgRole, selectedTeam]);


    const [totalPages, setTotalPages] = useState(0);

    const fetchUserProfile = useCallback(async (search, page) => {
        const controller = new AbortController(); // Create a new AbortController instance
        setLoading(true);
        try {
            const params = new URLSearchParams();
            if (search) {
                params.append(selectedOption.value, search);
            } else if (orgRole) {
                params.append("orgRole", orgRole);
            } else if (selectedTeam) {
                params.append("orgTeam", selectedTeam.value);
            } else if (!search || !orgRole || !selectedTeam) {
                params.append("page", page);
            }
            const response = await getDataWithAuth(`admin/api/user/usersFilter?${params.toString()}`);
            if (response && response.data) {
                console.log(response.data.users, "usersusers")
                const { users, count, totalPages } = response.data;
                if (page === 1) {
                    setProfilesList(users);
                } else if (!search || !orgRole || !selectedTeam) {
                    setProfilesList(prev => [...prev, ...users]);
                }
                setTotalProfiles(count); // Update with total count
                setTotalPages(totalPages); // Update with total pages

                if (page === 1) setSelectedUser(users[0]);
            }   
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Request canceled");
            } else {
                console.error("Error fetching data", error);
                setProfilesList([]);
            }
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }

        return () => {
            controller.abort(); // Cleanup and abort the previous request if a new one comes in
        };
    }, [search, orgRole, selectedTeam]);



    const handleScroll = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = window.innerHeight;
        const isAtBottom = (scrollTop + clientHeight >= scrollHeight - 50);

        if (isAtBottom && !loadingMore && !search) {
            // Fetch more data if not at the last page and profilesList length is less than totalProfiles
            if (currentPage < totalPages) {
                setLoadingMore(true);
                setProfilesList(prev => [...prev, ...profilesList]);
                setCurrentPage(prevPage => prevPage + 1);
            }
        }
    }, [loadingMore, currentPage, totalPages]);

    useEffect(() => {
        if (!manageTeam) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }

    }, [handleScroll]);

    const userDetailHandle = (item) => {
        setSelectedUser(item)
    }


    // 

    const createHandle = () => {
        setCreateProfileModal(true);
    };


    const manageTeamHandle = () => {
        setManageTeam(true)
    }

    const backButton = () => {
        setManageTeam(false)
    }


    useEffect(() => {
    }, [profilesList])


    return (
        <>
            <Container fluid className={`profileWrapper  ${manageTeam ? "container" : "container-fluid"}`}>
                <Row className={`headSec ${showLeftSidebar ? 'show' : 'hide'}`}>
                    <Col>
                        <div className="mb-3 headTop">
                            <h1>
                                {manageTeam ?
                                    <>
                                        <Link onClick={backButton}>
                                            <img src={process.env.PUBLIC_URL + 'images/sidebar/white_back_icon.svg'} alt="backbutton" />
                                        </Link>
                                        Back
                                    </>
                                    :
                                    <>
                                        <Link to="/dashboard">
                                            <img src={process.env.PUBLIC_URL + 'images/sidebar/white_back_icon.svg'} alt="backbutton" />
                                        </Link>
                                        User Panel
                                    </>
                                }
                            </h1>

                            <div className="adminDetail">
                                {manageTeam ? "" :
                                    <div className="addMoreSewa">
                                        <Button onClick={createHandle} className="createBtn">
                                            <img src={process.env.PUBLIC_URL + 'images/sidebar/plus_icon_white.svg'} alt="Schedule" />Create New User
                                        </Button>
                                        {/* <Dropdown className="customDropdown mr-2" onSelect={handleSelect}>
                                    <Dropdown.Toggle id="dropdown-basic" className="createBtn">
                                        <img src={process.env.PUBLIC_URL + 'images/sidebar/plus_icon_white.svg'} alt="Schedule" />
                                        {`Create New ${selectedItem ? selectedItem : ""}`}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="User">User</Dropdown.Item>
                                        <Dropdown.Item eventKey="Zone">Zone</Dropdown.Item>
                                        <Dropdown.Item eventKey="Team">Team</Dropdown.Item>
                                        <Dropdown.Item eventKey="Branch">Branch</Dropdown.Item>
                                        <Dropdown.Item eventKey="Role">Role</Dropdown.Item>
                                        <Dropdown.Item eventKey="Area">Area</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                        <Button onClick={manageTeamHandle} className={`createBtn ml-2 ${manageTeam ? "active" : ""}`}>
                                            Manage Teams
                                        </Button>
                                    </div>
                                }

                                <LoggedinProfile />
                            </div>
                        </div>
                    </Col>
                </Row>

                {manageTeam && manageTeam ? <ManageTeam /> : <>
                    <Row>
                        <Col className={`userListView ${showLeftSidebar ? 'show' : 'hide'}`} md={8}>
                            <div className="d-flex outerFilterTab">
                                <ul className="tabs filterTab">
                                    <li>
                                        <InputGroup className="searchGroup">
                                            <ReactSelect
                                                name="search"
                                                onChange={handleSearchOptions}
                                                options={searchOptions}
                                                placeholder="Search"
                                                value={selectedOption}
                                            />
                                            <Form.Control
                                                placeholder={`Search By ${selectedOption?.label}`}
                                                aria-describedby="basic-addon2"
                                                onChange={searchHandle}
                                                value={search}
                                            />

                                            <InputGroup.Text onClick={handleSearchClick}>
                                                <img className='userImg' src={process.env.PUBLIC_URL + 'images/pages/search_icon.svg'} alt="search_icon" />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </li>
                                    <li className="allBtn">
                                        <Button
                                            onClick={() => searchHandleBtn("")}
                                            type="button"
                                            className={`btn btn-primary ${!orgRole && !selectedTeam ? "active" : ""
                                                }`}
                                        >
                                            All
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={() => searchHandleBtn(1)}
                                            type="button"
                                            className={`btn btn-primary ${orgRole === 1 ? "active" : ""
                                                }`}
                                        >
                                            Branch Head
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={() => searchHandleBtn(2)}
                                            type="button"
                                            className={`btn btn-primary ${orgRole === 2 ? "active" : ""
                                                }`}
                                        >
                                            Area Incharge
                                        </Button>
                                    </li>
                                    <li className={`${!orgRole && selectedTeam ? "active" : ""
                                        }`}>
                                        <ReactSelect
                                            name="team"
                                            onChange={handleTeamChange}
                                            options={teams}
                                            placeholder="Select a Team"
                                        />
                                    </li>
                                </ul>
                            </div>
                            {loading && profilesList.length == 0 ? (
                                <div className="text-center loader mt-3">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (<div className="inviteesList">
                                {profilesList && profilesList.length > 0 ? (
                                    profilesList.map((item) => (
                                        <div>
                                            <button
                                                className={`invitee ${selectedUser && selectedUser.id === item.id ? 'active' : ''}`}
                                                key={item.id}
                                                onClick={() => {
                                                    // setSelectedUser(item);
                                                    userDetailHandle(item)
                                                    toggleSidebar();
                                                }}>
                                                <div>
                                                    <div className='user'>
                                                        {item.imgUrl && item.imgUrl ? (
                                                            <ImageWithFallback className="userImg" src={item.imgUrl} fallbackSrc={fallbackImageUrl} alt="Invite Icon" />
                                                        ) : (
                                                            <img className="userImg" src={fallbackImageUrl} alt="Invite Icon" />
                                                        )}
                                                        <div className='userName'>
                                                            <label>
                                                                {item.name} <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + '/images/pages/redTag_icon.svg'} alt="Tag Icon" />{item.currentSadhnaStreak}</span>
                                                            </label>
                                                            <span className='location'>{item.areaCode}<span className='uid'>UID-{item.uid}</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="tags mt-2">
                                                        <ul>
                                                            <li><button className="tag">#{item.userRole}</button></li>
                                                            {item.orgTeam.name && <li><button className="tag">#{item.orgTeam.name}</button></li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <Form className="adminLabel">
                                                    <Form.Label className="form-check-label">Admin</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={item.userRole === "ADMIN"}
                                                        onChange={(e) => handleToggleChange(item.id, e.target.checked)}
                                                    />
                                                </Form>
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center loader mt-3">
                                        No Record
                                    </div>
                                )}
                            </div>)}

                            {loadingMore && (
                                <div className="text-center my-4">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}

                            {/* {!loading && profilesList.length > 0 ? (
                                
                            ) : ""} */}
                            {/* <Pagination>
                                <Pagination.Prev onClick={() => paginate(Math.max(currentPage - 1, 1))} />
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                                    <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                                        {number}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => paginate(Math.min(currentPage + 1, totalPages))} />
                            </Pagination> */}
                        </Col>
                        {profilesList.length > 0 ?
                            <Col md={4} className={`sticky-user-detail userProfileView ${showRightSidebar ? 'show' : 'hide'}`}>
                                <UserDetail backListHandle={backListHandle} user={selectedUser} />
                            </Col>
                            : ""}
                    </Row>
                </>

                }


                {showDeleteProfile && <DeleteProfile show={showDeleteProfile} hide={closeDeleteProfile} />}
                {createProfileModal && <CreateProfileModal show={createProfileModal} hide={closeCreateProfile} />}
            </Container>
        </>
    );
}

export default UserProfiles;
