import { useState } from 'react';
import { Row, Col, Form, Button, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
function AddInvitees({ show, onHide, }) {
  const [selectedInvitees, setSelectedInvitees] = useState([]);

  const [formData, setFormData] = useState({
    invitees: []
  });
  const handleInviteesChange = (selectedOptions) => {
    setSelectedInvitees(selectedOptions);
    setFormData({
      ...formData,
      invitees: selectedOptions.map(option => option.value)
    });
  };

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];



  return (
    <>
      <Modal  size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Select Invitees</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <Row className="innerInvitees">
            <Col lg={12}>
              <Row>
                <Col>
                  <Select
                    closeMenuOnSelect={false}
                    options={options}
                    placeholder="Zone"
                    isMulti
                  />
                </Col>
                <Col>
                  <Select
                    closeMenuOnSelect={false}
                    options={options}
                    placeholder="Branch"
                    isMulti
                  />
                </Col>
                <Col>
                  <Select
                    closeMenuOnSelect={false}
                    options={options}
                    placeholder="Area"
                    isMulti
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <Select
                    closeMenuOnSelect={false}
                    options={options}
                    isMulti
                    placeholder="Search Invitees"
                    onChange={handleInviteesChange}
                    value={selectedInvitees} />
                </Col>
              </Row>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddInvitees; 