import React from "react"
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import LoggedinProfile from "./LoggedinProfile"

const PageHeader = ({ pagename, backArrow, url }) => {
    return (
        <>
            <div className="headTop">
                <h1>{pagename}</h1>
                <LoggedinProfile />
            </div>
        </>
    )
}

export default PageHeader