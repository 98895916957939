import React from 'react';
import Zone from './Zone';
import { Tab, Tabs } from 'react-bootstrap';
import Team from './Team';
import Role from './Role';
function ManageTeam() {


  return (
    <div className="container manageTeamOuter">
      <h1 className='head'>Manage Team</h1>

      <Tabs
        defaultActiveKey="zone"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="zone" title="Zone">
          <Zone />
        </Tab>
        <Tab eventKey="team" title="Team">
          <Team />
        </Tab>
        <Tab eventKey="role" title="Role">
          <Role />
        </Tab>
        
      </Tabs>


    </div>
  );
}

export default ManageTeam;
