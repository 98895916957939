import React, { useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import NavBar from "../components/NavBar";
import AddInvitees from "./modal/AddInvitees";

const UpcomingEvents = () => {
    const [selectedInvitees, setSelectedInvitees] = useState([]);
    const [showInvitees, setShowInvitees] = useState(false);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        eventTitle: "",
        thumbnail: null,
        conductedBy: "Dr Rajbir",
        eventDate: "",
        eventTime: "",
        eventType: "offline",
        eventURL: "",
        eventLocation: "",
        eventDescription: "",
        invitees: []
    });

    const validateForm = () => {
        const errors = {};
        // Add validation logic here, for example:
        if (!formData.eventTitle.trim()) {
            errors.eventTitle = "event title is required";
        }
        // Add more validations for other fields
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ""
        });
    };


    const inviteesHandle = () => {
        setShowInvitees(true)
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            thumbnail: file
        });
    };

    // const handleeventTypeChange = (e) => {
    //     const { value } = e.target;
    //     setEventType(value);
    //     setFormData({
    //         ...formData,
    //         eventType: value
    //     });
    // };

    const handleInviteesChange = (selectedOptions) => {
        setSelectedInvitees(selectedOptions);
        setFormData({
            ...formData,
            invitees: selectedOptions.map(option => option.value)
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };




    return (
        <>
            <NavBar />
            <section className="py-0 py-xl-5 bg-success bg-opacity-10 mt-5">
                <div className="container col-7">
                    <div className="heading">
                        <h3>Schedule Upcoming Event</h3>
                        <Link to="/eventList" className="back"><i className="bi bi-back"></i>Back</Link>
                    </div>
                    <Row>
                        <Col>
                            <Form className="eventWrapper rounded-3" onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Event Title</Form.Label>
                                            <Form.Control type="text" placeholder="event Title" name="eventTitle" onChange={handleInputChange} />
                                            {errors.eventTitle && <Alert variant="danger">{errors.eventTitle}</Alert>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Thumbnail (Optional)</Form.Label>
                                            <div className="thumbnail">
                                                {formData.thumbnail && (
                                                    <img className="img" src={URL.createObjectURL(formData.thumbnail)} alt="Thumbnail" style={{ maxWidth: "100%", marginTop: "10px" }} />
                                                )}
                                                <Form.Control type="file" onChange={handleFileChange} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Conducted By</Form.Label>
                                            <Form.Select aria-label="Conducted By" name="conductedBy" onChange={handleInputChange}>
                                                <option value="Dr Rajbir">Dr Rajbir</option>
                                                <option value="Jasveer">Jasveer</option>
                                                <option value="Jagjit">Jagjit</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label>Event Date</Form.Label>
                                                <Form.Control placeholder="event Date" type="date" name="eventDate" onChange={handleInputChange} />
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Label>Event Time</Form.Label>
                                                <Form.Control placeholder="Event Time" type="time" name="eventTime" onChange={handleInputChange} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Event Description</Form.Label>
                                            <Form.Control as="textarea" rows={2} name="eventDescription" onChange={handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3} className="d-flex align-items-end mb-3">
                                        <Button onClick={inviteesHandle} className="btn btn-primary blueBtn col-12" variant="primary" type="submit">
                                        <i class="bi bi-person-plus-fill"></i> Add Invitees
                                        </Button>
                                    </Col>
                                </Row>
                                {/* <div className="invitees">
                                <h4>Add Invitees</h4>
                                <Row className="innerInvitees bg-success bg-opacity-10">
                                    <Col lg={12}>
                                        <Row>
                                            <Col>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    placeholder="Zone"
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    placeholder="Branch"
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    placeholder="Area"
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    placeholder="Groups"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-2">
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    options={options}
                                                    isMulti
                                                    placeholder="Search Invitees"
                                                    onChange={handleInviteesChange}
                                                    value={selectedInvitees} />
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </div> */}
                                <div className="createEventFooter">
                                    <Button className="btn" variant="primary" type="submit">
                                        Create event
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </section>
            {showInvitees && <AddInvitees show={showInvitees} onHide={() => setShowInvitees(false)} />}
        </>
    );
};

export default UpcomingEvents;
