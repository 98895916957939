import React, { useState, useEffect } from 'react';
import { getDataWithAuth } from '../../api/api';
import axios from 'axios';
import { API_URL } from '../../config/config';
import { toast } from 'react-toastify';
import DeleteProfile from '../modal/DeleteProfile';

const Team = () => {
    // New states for teams and sub-teams
    const token = localStorage.getItem('token');
    const [teams, setTeams] = useState([]);
    const [subTeams, setSubTeams] = useState({});
    const [expandedTeams, setExpandedTeams] = useState({});
    const [editingItem, setEditingItem] = useState(null);
    const [newItemLabel, setNewItemLabel] = useState('');
    const [newLabel, setNewLabel] = useState('');
    const [addingItem, setAddingItem] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        fetchTeams(); // Fetch teams on component mount
    }, []);



    // Fetch teams
    const fetchTeams = async () => {
        try {
            const response = await getDataWithAuth('admin/api/organization/team/list');
            const options = response.data.map((team) => ({
                value: team.id,
                label: team.name,
            }));
            setTeams(options);
        } catch (error) {
            console.error('Error fetching teams', error);
        }
    };

    // Fetch sub-teams
    const fetchSubTeams = async (teamId) => {
        if (subTeams[teamId]) return;
        try {
            const response = await getDataWithAuth(`admin/api/organization/team/list?parentId=${teamId}`);
            const options = response.data.map((subTeam) => ({
                value: subTeam.id,
                label: subTeam.name,
            }));
            setSubTeams((prevSubTeams) => ({
                ...prevSubTeams,
                [teamId]: options,
            }));
        } catch (error) {
            console.error('Error fetching sub-teams', error);
        }
    };

    // Toggle team expansion and fetch sub-teams
    const toggleTeamExpansion = (teamId) => {
        setExpandedTeams((prevExpandedTeams) => ({
            ...prevExpandedTeams,
            [teamId]: !prevExpandedTeams[teamId],
        }));

        if (!expandedTeams[teamId]) {
            fetchSubTeams(teamId);
        }
    };

    // Start editing or adding items
    const startEditing = (type, item, ) => {
        setEditingItem({ type, value: item.value, teamId: item.teamId });
        setNewLabel(item.label);
    };

    const saveEdit = async () => {

        if(!editingItem){
            toast.error("Cannot be empty.");
            return
        }


        const { type, value, teamId } = editingItem;
        console.log(editingItem, )
        let url = '';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            if (type === 'team') {
                url = `${API_URL}admin/api/organization/team/update/${value}`;
            } else if (type === 'sub-team') {
                url = `${API_URL}admin/api/organization/team/update/${value}`;
            }

           
            if (type === 'team') {
                await axios.put(url, { name: newLabel}, { headers });
                setTeams(teams.map((team) => (team.value === value ? { ...team, label: newLabel } : team)));
            } else if (type === 'sub-team') {
                await axios.put(url, { name: newLabel, parentId: teamId }, { headers });
                setSubTeams((prevSubTeams) => ({
                    ...prevSubTeams,
                    [teamId]: prevSubTeams[teamId]?.map((subTeam) =>
                        subTeam.value === value ? { ...subTeam, label: newLabel } : subTeam
                    ),
                }));

            }

            setEditingItem(null);
        } catch (error) {
            console.error(`Failed to update ${type}:`, error);
        }
    };



    const deleteItem = async () => {
        if (!itemToDelete) return;

        const { type, value, teamId } = itemToDelete
        console.log(value, teamId)
        let url = '';
        let deleteResponse;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            type = 3
            if (type === 2) {
                url = `${API_URL}admin/api/organization/team/delete/${value}`;
                deleteResponse = await axios.delete(url, { headers });

                // Update teams state immediately
                setTeams((prevTeams) => prevTeams.filter((team) => team.value !== value));
                toast.success(deleteResponse.data.message);
            } else if (type === 'sub-team') {
                url = `${API_URL}admin/api/organization/team/delete/${value}`;
                deleteResponse = await axios.delete(url, { headers });
                console.log(deleteResponse, "deleteResponse")
                // Ensure subTeams exists for this teamId before updating
                setSubTeams((prevSubTeams) => {
                    if (!prevSubTeams[teamId]) return prevSubTeams;
                    console.log("11")
                    return {
                        ...prevSubTeams,
                        [teamId]: prevSubTeams[teamId].filter(
                            (subTeam) => subTeam.value !== value
                        ),
                    };
                });
                toast.success(deleteResponse.data.message);
            }

            // Close the delete modal after the item is deleted
            closeDeleteModal();
        } catch (error) {
            console.error(`Failed to delete ${type}:`, error);
            toast.error(`Failed to delete ${type}`);
        }
    };



    const startAddingItem = (type, parentId) => {
        if (type === 'team') {
            setAddingItem({ type, parentId: null });
        } else if (type === 'sub-team') {
            console.log(type, parentId, "type, parentI")
            setAddingItem({ type, parentId });
            // setExpandedTeams((prevExpandedTeams) => ({
            //     ...prevExpandedTeams,
            //     [parentId]: true,
            // }));
        }
        setNewItemLabel('');
    };

    const saveNewItem = async () => {
        if(!newItemLabel){
            toast.error("Cannot be empty.");
            return
        }

        const { type, parentId } = addingItem;

        let url = '';
        const body = { name: newItemLabel, parentId: parentId };

        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            if (type === 'team') {
                url = `${API_URL}admin/api/organization/team/create`;
            } else if (type === 'sub-team') {
                url = `${API_URL}admin/api/organization/team/create`; // Assuming the same URL for both
            }

            const response = await axios.post(url, body, { headers });
            const newTeam = response.data;
            const newItem = { value: newTeam.data.id, label: newTeam.data.name };

            if (type === 'team') {
                setTeams([...teams, newItem]);
                toast.success(newTeam.message);
            } else if (type === 'sub-team') {
                // Ensure subTeams[parentId] is an array before spreading it
                setSubTeams({
                    ...subTeams,
                    [parentId]: [...(subTeams[parentId] || []), newItem],
                });
                toast.success(newTeam.message);
            }

            setAddingItem(null);
            setNewItemLabel('');
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
            console.error(`Failed to create ${type}:`, error);
        }
    };



    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);
    };

    const cancelAddItem = () => {
        setAddingItem(null);
        setNewItemLabel('');
    };

    const openDeleteModal = (type, item) => {
        console.log(type, item, "ype, item")
        setItemToDelete({ type, value: item.value, teamId: item.teamId });
        setShowDeleteModal(true);
    };

    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Team</th>
                        <th className='text-end'>
                            <button type="button" class="createBtn btn btn-primary addMoreBtn"
                                onClick={() => startAddingItem('team')}>
                                <img src="images/sidebar/plus_icon.svg" alt="Schedule" /> Add New Team
                            </button>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addingItem?.type === 'team' && (
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={newItemLabel}
                                    onChange={(e) => setNewItemLabel(e.target.value)}
                                    className="form-control d-inline-block me-2"
                                />
                            </td>
                            <td className='text-end'>
                                <button className="btn btn-primary me-2 saveBtn" onClick={saveNewItem}>
                                    Save Team
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={cancelAddItem}
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    )}
                    {teams.map((team) => (
                        <React.Fragment key={team.value}>
                            <tr>
                                <td onClick={() => toggleTeamExpansion(team.value)} style={{ cursor: 'pointer' }}>
                                    {editingItem?.type === 'team' && editingItem.value === team.value ?
                                        <input
                                            type="text"
                                            value={newLabel}
                                            onChange={(e) => setNewLabel(e.target.value)}
                                            className="form-control d-inline-block me-2"

                                        /> : (
                                            <span className='toggleLink'>
                                                {expandedTeams[team.value] ? <i class="bi bi-dash-lg"></i> : <i class="bi bi-plus-lg"></i>}{team.label}</span>
                                        )
                                    }
                                </td>
                                <td className='text-end'>
                                    {editingItem?.type === 'team' && editingItem.value === team.value ? (
                                        <div>
                                            <button className="btn btn-primary saveBtn" onClick={saveEdit}>
                                                Save
                                            </button>
                                            <button className="bbtn btn-secondary" onClick={() => setEditingItem(null)}>
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button type="button" class="createBtn btn btn-primary addMoreBtn"
                                                onClick={() => startAddingItem('sub-team', team.value)}>
                                                <img src="images/sidebar/plus_icon.svg" alt="Schedule" />Add Sub-Team
                                            </button>
                                            <button
                                                className="btn btn-primary editBtn"
                                                onClick={() =>
                                                    // startEditing('team', team)
                                                    startEditing('team', { ...team, teamId: team.value })
                                                    
                                                }
                                            >
                                                <img class="userImg" src="images/sidebar/edit_icon.svg" alt="edit_icon" />
                                                Edit
                                            </button>
                                            <button
                                                className="btn deleteBtn"
                                                onClick={() => openDeleteModal('team', team)}
                                            >
                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" /> Delete
                                            </button>

                                        </div>
                                    )}
                                </td>
                            </tr>

                            {addingItem?.type === 'sub-team' && addingItem.parentId === team.value && (
                                <tr>
                                    <td style={{ paddingLeft: '30px' }}>
                                        <input
                                            type="text"
                                            value={newItemLabel}
                                            onChange={(e) => setNewItemLabel(e.target.value)}
                                            className="form-control d-inline-block me-2"
                                        />
                                    </td>
                                    <td className='text-end'>
                                        <button className="btn btn-primary me-2 saveBtn" onClick={saveNewItem}>
                                            Save Sub-Team
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={cancelAddItem}
                                        >
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            )}

                            {expandedTeams[team.value] && subTeams[team.value] &&
                                <tr>
                                    <td colSpan="2" className={`pe-0 pt-0  indentTable table-active ${expandedTeams[team.value] ? "" : "pb-0"}`}>
                                        <table className={`table ${expandedTeams[team.value] ? "" : "p-0 m-0"}`}>
                                            <tbody>

                                                {expandedTeams[team.value] &&
                                                    subTeams[team.value]?.map((subTeam) => (
                                                        <React.Fragment key={subTeam.value}>
                                                            <tr>
                                                                <td>
                                                                    {editingItem?.type === 'sub-team' && editingItem.value === subTeam.value ?
                                                                        <input
                                                                            type="text"
                                                                            value={newLabel}
                                                                            onChange={(e) => setNewLabel(e.target.value)}
                                                                            className="form-control d-inline-block me-2"
                                                                        /> :
                                                                        <>
                                                                            {subTeam?.label}
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td className='text-end'>
                                                                    {editingItem?.type === 'sub-team' && editingItem.value === subTeam.value ? (
                                                                        <div>

                                                                            <button className="btn btn-primary saveBtn" onClick={saveEdit}>
                                                                                Save
                                                                            </button>
                                                                            <button className="bbtn btn-secondary" onClick={() => setEditingItem(null)}>
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-primary editBtn"
                                                                                onClick={() =>
                                                                                    startEditing('sub-team', { ...subTeam, teamId: team.value })
                                                                                }
                                                                            >
                                                                                <img class="userImg" src="images/sidebar/edit_icon.svg" alt="edit_icon" />
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="btn deleteBtn"
                                                                                onClick={() => openDeleteModal('sub-team', { ...subTeam, teamId: team.value})}
                                                                            >
                                                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" /> Delete
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    ))}

                </tbody>
            </table>

            {showDeleteModal && <DeleteProfile
                show={showDeleteModal}
                hide={closeDeleteModal}
                onConfirm={deleteItem}
                item={itemToDelete}
            />}
        </>
    );
};

export default Team;
