import React, { useState, forwardRef } from "react"
import { Accordion, Button, Card, Container, Dropdown, Form, NavDropdown, Row, } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Sidebar from "../components/Sidebar"
import CreateSewaModal from "./modal/CreateSewaModal";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import PageHeader from "../components/PageHeader";
import AccordionComp from "../components/Accordion";
import ReactDatePicker from "react-datepicker";
import AssigneeList from "./modal/AssigneeList";

const CreateTasks = () => {
    const [showList, setShowList] = useState(false);
    const [newTask, setNewTask] = useState(false);
    const [addSewa, setAddSewa] = useState(false)
    const [tag, setTag] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const assigneeHide = () => SetAssigneeShow(false);
    const handleShow = () => setShow(true);
    const [isOpen, setIsOpen] = useState(false);
    const [assigneeShow, SetAssigneeShow] = useState(false);
    const [startDate, setStartDate] = useState(new Date());


    const newTaskHandle = () => {
        setNewTask(!newTask);
    };

    const toggleList = () => {
        setShowList(!showList);
    };

    const handleTagChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            setTag(newValue);
        } else {
            setTag(newValue);
        }
    };

    const addSevaHandle = () => {
        setAddSewa(!addSewa)
    }



    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    const saveHandle = () => {
        setAddSewa(false)
    }

    const CalendarView = forwardRef(({ value, onClick }, ref) => (
        <button className="custom-calendar" onClick={onClick} ref={ref}>
            <img width="22px" src={process.env.PUBLIC_URL + 'images/pages/calendar_icon.svg'} alt="calendar icon" />
        </button>
    ));

    const assigneeHandle = () => {
        SetAssigneeShow(true)
    }


    return (
        <>
            <Container fluid>
                <Row className="wrapper sewaModule">
                    <Sidebar />
                    <div className="content">
                        <PageHeader pagename={"Create Sewa"} />
                        <div className="listOuter">
                            <div className="d-flex">
                                <ul className="tabs">
                                    <li>
                                        <button type="button" class="btn btn-primary active">
                                            All <span class="badge">(20)</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-primary">
                                            <i width="20px" class="bi bi-star"></i>
                                            Starred <span class="badge">(5)</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-primary">
                                            <img width="15px" src={process.env.PUBLIC_URL + 'images/pages/single_person_icon.svg'} alt="filter icon" />
                                            Individual <span class="badge">(65)</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-primary">
                                            <img width="20px" src={process.env.PUBLIC_URL + 'images/pages/group_person_icon.svg'} alt="filter icon" />
                                            Group  <span class="badge">(7)</span>
                                        </button>
                                    </li>
                                    <li>
                                        <Form.Select>
                                            <option disabled selected >Not Started (33)</option>
                                            <option value="Dr Rajbir">Not Started (33)</option>
                                            <option value="Dr Rajbir">InProgress (33)</option>
                                            <option value="Dr Rajbir">Completed (33)</option>
                                        </Form.Select>
                                    </li>
                                </ul>
                                <div className="addMoreSewa">
                                    <Button className="createBtn"> <img src={process.env.PUBLIC_URL + 'images/sidebar/plus_icon_white.svg'} alt="Schedule" /> New Section</Button>
                                </div>
                            </div>

                            <div className="heading">
                                <div className="progressOuter">
                                    <h3>All Tasks(57)</h3>
                                    <ProgressBar label={`40%`} now={40} key={3} />
                                </div>
                                <div className="filter">
                                    <Dropdown className="customDropdown">
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img className="filterIcon" src={process.env.PUBLIC_URL + 'images/pages/filter_icon.svg'} alt="filter icon" />
                                            Filter
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>


                            <div className="collapsible-container">
                                <div className='accordionHead'>
                                    <h4>Default Section</h4>
                                    <div>
                                        <Button onClick={addSevaHandle} className="createSectionicon"><img src={process.env.PUBLIC_URL + 'images/sidebar/plus_icon.svg'} alt="plus icon" /></Button>
                                        <button className={`arrowIcon ${isOpen ? 'open' : 'closed'}`} onClick={toggleCollapsible}><img src={process.env.PUBLIC_URL + 'images/pages/arrow_icon.svg'} alt="plus icon" /></button>
                                    </div>
                                </div>
                                <div className={`collapsible-content ${isOpen ? 'open' : 'closed'}`}>
                                    <div className="cards">
                                        <div className="row w-100">
                                            {addSewa &&
                                                <div className="col-lg-4">
                                                    <Card className="createCard">
                                                        <Card.Body>
                                                            <Card.Title className="mb-2">
                                                                <button className="btn assignee" onClick={handleShow}><img src={process.env.PUBLIC_URL + 'images/pages/assignee_icon.svg'} alt="plus icon" /></button>
                                                                <button onClick={saveHandle} className="btn saveBtn">Done</button>
                                                            </Card.Title>
                                                            <Form>
                                                                <Form.Group className="mb-2">
                                                                    <Form.Control type="text" placeholder="Enter title here" />
                                                                </Form.Group>
                                                                <Form.Group className="mb-2">
                                                                    <Form.Control as="textarea" rows={1} type="text" placeholder="Description" />
                                                                </Form.Group>
                                                            </Form>
                                                            <div className="cardFooter">
                                                                {/* <CreatableSelect
                                                                    onChange={handleTagChange}
                                                                    value={tag}
                                                                    placeholder="Add tag"
                                                                    formatCreateLabel={(inputValue) => `Select "${inputValue}"`}
                                                                /> */}
                                                                <span className="tag">#group seva</span>
                                                                <span className="flags">
                                                                    <button className="btn"><img width="22px" src={process.env.PUBLIC_URL + 'images/pages/hashTag_icon.svg'} alt="notifyUsers" /></button>

                                                                    <ReactDatePicker
                                                                        selected={startDate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        customInput={<CalendarView />}
                                                                        portalId="root-portal"
                                                                    />
                                                                    {/* <button className="btn"><img width="22px" src={process.env.PUBLIC_URL + 'images/pages/calendar_icon.svg'} alt="notifyUsers" /></button> */}
                                                                    <button className="btn"><i class="bi bi-star"></i></button>
                                                                    <button className="btn"><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/flag_icon.svg'} alt="notifyUsers" /></button>
                                                                </span>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            }
                                            <div className="col-lg-4">
                                                <Card >
                                                    <Card.Body>
                                                        <Card.Title>
                                                            <div className="head">
                                                                <button onClick={assigneeHandle} className="notifyUsers">
                                                                    <ul>
                                                                        <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                                        <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                                        <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                                        <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                                        <Link><i class="bi bi-plus"></i>50</Link>
                                                                    </ul>
                                                                </button>
                                                                <div className="dateOuter">
                                                                    <img src={process.env.PUBLIC_URL + 'images/pages/calendar_icon.svg'} alt="notifyUsers" />
                                                                    <span className="date">5/12/2024</span>
                                                                </div>
                                                            </div>
                                                            <div className="customDropdown">
                                                                <Dropdown className="customDropdown">
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        <i class="bi bi-three-dots-vertical"></i>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <ul>
                                                                            <li className="edit"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit" />Edit</button></li>
                                                                            <li className="archive"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/archive_icon.svg'} alt="archive" />Archive</button></li>
                                                                            <li className="delete"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" />Delete</button></li>
                                                                        </ul>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </Card.Title>
                                                        <div className="userInfo">
                                                            <label>Monthly Meet</label>
                                                            <p className="description">Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                                        </div>
                                                        <div className="progressOuter">
                                                            <span className="valueCount"><span className="done">28</span>/<span className="total">35</span></span>
                                                            <ProgressBar now={40} key={3} />
                                                        </div>
                                                        <div className="cardFooter">
                                                            <span className="tag">#group seva</span>
                                                            <span className="flags">
                                                                <button className="btn"><i class="bi bi-star"></i></button>
                                                                <button className="btn"><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/flag_icon.svg'} alt="notifyUsers" /></button>
                                                            </span>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                {show && <CreateSewaModal show={show} handleClose={handleClose} />}
                {assigneeShow && <AssigneeList assigneeShow={assigneeShow} assigneeHide={assigneeHide} />}
            </Container>
        </>
    )
}

export default CreateTasks