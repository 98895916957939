
import { Button, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
function DeleteProfile({ show, hide, onConfirm, item }) {

  console.log(item)

  return (
    <>
      <Modal size="md" show={show} onHide={hide}>
        <Modal.Body className='modalBody'>
          <div className='deleteMessage'>
            <img src={process.env.PUBLIC_URL + 'images/sidebar/question_icon.svg'} alt="edit_icon" />
            <label>Are you sure you want to delete {item?.type ? <>this {item?.type}</> : ""} {item?.label}?</label>
          </div>
          <div className='deletebuttons'>
            <Button variant="secondary" className='no' onClick={hide}>
              No
            </Button>
            <Button className='yes' variant="primary" onClick={onConfirm}>
              Yes
            </Button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteProfile; 