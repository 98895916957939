import React from "react"
import { Button, Card, Dropdown, Form, } from "react-bootstrap"
import { Link, useNavigate } from 'react-router-dom';
import NavBar from "../components/NavBar";

const EventList = () => {
    const navigate = useNavigate();
    const newEvent = () => {
        navigate('/upcomingEvents');
    }




    return (
        <>
            <NavBar />
            <section className="py-0 py-xl-5 bg-success bg-opacity-10 mt-5">
                <div className="container col-12 mt-3">
                    <div className="eventListWrapper">
                        <div className="heading">
                            <h3>Event List</h3>
                            <div className="d-flex">
                                {/* <Link to="/dashboard" className="back"><i className="bi bi-back"></i>Back</Link> */}
                                <Button onClick={newEvent} className="createBtn"> <i class="bi bi-person-plus-fill"></i> Schedule New Upcoming Event </Button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="filter">
                                <Form.Control placeholder="Search Events" type="text" />
                            </div>
                            <div className="cards">
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Title</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delete</li>
                                                                <li>Edit</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Monthly Meet</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delete</li>
                                                                <li>Edit</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Monthly Meet</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delee List</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Monthly Meet</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delete</li>
                                                                <li>Edit</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>


                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Monthly Meet</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delete</li>
                                                                <li>Edit</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="userInfo">
                                                <img src={process.env.PUBLIC_URL + 'images/userImag.jpeg'} />
                                                <div className="userHeading">
                                                    <label>Monthly Meet</label>
                                                    <span className="date">10 Feb 2024 at 8 PM</span>
                                                </div>
                                            </div>

                                            <div className="customDropdown">
                                                <Dropdown className="customDropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div>
                                                            <ul>
                                                                <li>Delete</li>
                                                                <li>Edit</li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Card.Title>
                                        <div className="notifyUsers">
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <li><img src={process.env.PUBLIC_URL + 'images/invite.png'} alt="notifyUsers" /></li>
                                                <Link><i class="bi bi-plus"></i>50 Other</Link>
                                            </ul>
                                        </div>
                                        <div className="location">
                                            DJJS Ashram, Chandigarh.
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="description">
                                            <p>Meeting description: All the brothers are kindly informed to attend this month's YPSS meet from telecasted from Nurmahal. </p>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EventList