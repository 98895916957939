import React, { useState } from "react";
import { Button, Dropdown, Nav } from "react-bootstrap";
import CreateProject from "../pages/modal/CreateProject";
import { Link } from "react-router-dom";

const Sidebar = () => {
    const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [showCreateproject, setShowCreateproject] = useState(false)

    const [projects, setProjects] = useState([
        { id: 'satsang', name: 'Satsang' },
        { id: 'guru-puja', name: 'Guru Puja' },
        { id: 'test-seva', name: 'Test Seva' },
        { id: 'pandal-sewa', name: 'Pandal Sewa' },
        { id: 'langar-sewa', name: 'Langar Sewa' }
    ]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newProjects = Array.from(projects);
        const [removed] = newProjects.splice(result.source.index, 1);
        newProjects.splice(result.destination.index, 0, removed);
        setProjects(newProjects);
    };

    const onDragStart = (start) => {
        console.log('Dragging started:', start.draggableId);
    };

    const onDragUpdate = (update) => {
        console.log('Dragging updated:', update.draggableId);
    };

    const createProjectHandle = () => {
        setShowCreateproject(!showCreateproject)
    }

    return (
        <>
            <Nav className="sidebar" id="scrollRight">
                <div className="headingSearch">
                    <h2><Link to="/dashboard"><img src={process.env.PUBLIC_URL + 'images/sidebar/backIcon.svg'} alt="backbutton" /></Link>Sewa</h2>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search here" />
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button">
                                <i class="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="globalTabs">
                    <button className="allSewa allModule active">
                        <div className="iconAndCount">
                            <span className="iconOuter">
                                <img src={process.env.PUBLIC_URL + 'images/sidebar/sewa_icon.svg'} alt="Sewas" />
                            </span>
                            <strong className="totalCount">98</strong>
                        </div>
                        <label>My Task</label>
                    </button>
                    <button className="starredBtn allModule">
                        <span className="iconAndCount">
                            <span className="iconOuter today">
                                <img src={process.env.PUBLIC_URL + 'images/sidebar/calendar_icon.svg'} alt="Schedule" />
                            </span>
                            <span className="totalCount">16</span>
                        </span>
                        <label>Today</label>
                    </button>
                    <button className="schedule allModule">
                        <span className="iconAndCount">
                            <span className="iconOuter starred">
                                <img src={process.env.PUBLIC_URL + 'images/sidebar/starred_icon.svg'} alt="Starred" />
                            </span>
                            <span className="totalCount">6</span>
                        </span>
                        <label>Starred</label>
                    </button>
                    <button className="urgent allModule">
                        <span className="iconAndCount">
                            <span className="iconOuter urgent">
                                <img src={process.env.PUBLIC_URL + 'images/sidebar/urgent_icon.svg'} alt="Urgent" />
                            </span>
                            <span className="totalCount">6</span>
                        </span>
                        <label>Urgent</label>
                    </button>
                </div>
                <div className="headingSearch d-flex">
                    <h3>Projects</h3>
                    <Button onClick={createProjectHandle} className="createBtn">  <img src={process.env.PUBLIC_URL + 'images/sidebar/plus_icon.svg'} alt="Schedule" /> Add New</Button>
                </div>
                <ul className="projects">
                    {showCreateproject &&
                        <li className="list">
                            <img src={process.env.PUBLIC_URL + 'images/sidebar/project_icon.svg'} alt="Sewas" />
                            <input className="createInput" type="text" placeholder="Add Tile" />
                            <Button onClick={createProjectHandle} className="createBtn">  <img src={process.env.PUBLIC_URL + 'images/pages/close_Icon.svg'} alt="Sewas" /></Button>
                        </li>
                    }

                    {projects.map((project) => (
                        <li className="list">
                            <Button>
                                <img src={process.env.PUBLIC_URL + 'images/sidebar/project_icon.svg'} alt="Sewas" /> {project.name}
                            </Button>
                            <div className="customDropdown">
                                <Dropdown className="customDropdown">
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li className="edit"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit" />Edit</button></li>
                                            <li className="archive"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/archive_icon.svg'} alt="archive" />Archive</button></li>
                                            <li className="delete"><button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" />Delete</button></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="headingSearch d-flex">
                    <h3>Hagtags</h3>
                </div>
                <div className="tags">
                    <li><button className="tag">#Langer</button></li>
                    <li><button className="tag">#Tent</button></li>
                    <li><button className="tag">#Sound</button></li>
                    <li><button className="tag">#Pandal</button></li>
                    <li><button className="tag">#Tent</button></li>
                </div>
                <div className="sidebarFooter">
                    <button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/archive_icon.svg'} alt="archive" />Archive</button>
                    <button className="btn"><img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon_gray.svg'} alt="delete" />Deleted</button>
                </div>
            </Nav>
            {/* {show && <CreateProject show={show} handleClose={handleClose} />} */}
        </>
    );
};

export default Sidebar;

